<template>
  <b-row class="mb-4 round-breakdown-table">
    <b-col cols="12">
      <b-card no-body class="table-card">
        <div class="d-flex mt-4 mx-4">
          <h5 class="m-0 align-self-center">Revenue and Cost</h5>
          <div class="align-self-center">
            <single-selection-filter
              v-if="!loadingWeeks && allTeamWeeks"
              v-model="selectedWeek"
              itemValue="name"
              :options="allWeeks"
              placeholder="Select week"
              badge
              :defaultItemIndex="0"
              class="ml-2 align-self-center"
            ></single-selection-filter>

            <scale-loader
              :loading="loadingWeeks"
              :color="loader.color"
              height="15px"
              class="ml-2 align-self-center"
            ></scale-loader>
          </div>
        </div>
        <div class="mx-4 my-2">
          <b-overlay
            :show="loadingRoundBreakdownTable && !skipLoading"
            rounded="sm"
            bg-color="white"
          >
            <b-table
              ref="table"
              v-if="(!loadingRoundBreakdownTable || skipLoading) && roundBreakdown"
              :key="`data-table-${reloadTable}`"
              :fields="roundBreakdown.fields"
              :items="roundBreakdown.data"
              :tbody-tr-class="rowClass"
              hover
              responsive
            >
              <template #thead-top>
                <b-tr>
                  <b-th
                    v-for="(th, index) in roundBreakdown.fieldGroup"
                    :key="`th-${index}`"
                    :colspan="th.items"
                    :class="{ 'th-bg-theme-secondary': th.show }"
                  >
                    <p
                      :id="`th-title-${index}`"
                      :class="{ 'sr-only': !th.show }"
                      class="mb-0 font-semi-bold team-name"
                    >
                      {{ th.title }}
                    </p>
                    <b-tooltip
                      :target="`th-title-${index}`"
                      placement="top"
                      variant="light"
                      boundary="window"
                      triggers="hover"
                    >
                      {{ th.title }}
                    </b-tooltip>
                    <div
                      class="px-3 py-1 mb-1 align-self-center chart-legend-line"
                      :style="`border: 1px solid ${th.borderColor}; backgroundColor: ${th.backgroundColor}`"
                    ></div>
                  </b-th>
                </b-tr>
              </template>

              <template
                v-for="(field, index) in roundBreakdown.fields"
                v-slot:[`cell(${field.key})`]="data"
              >
                <div v-if="field.key == 'options'" :key="`field-${index}`">
                  <b-badge
                    v-if="data.item.type"
                    :variant="data.item.type.toLowerCase() === 'revenue' ? 'success' : 'warning'"
                    pill
                  >
                    <span>{{ data.item.type }}</span>
                  </b-badge>
                  <span
                    class="ml-2"
                    :class="{
                      'font-semi-bold': data.item.option.toLowerCase().includes('total'),
                      'font-bold': data.item.option.toLowerCase() === 'total profit'
                    }"
                  >
                    {{ data.item.option }}
                  </span>
                  <i
                    v-if="data.item.option_desc.length > 0"
                    class="fa fa-info-circle text-info mx-1"
                    v-tooltip="{
                      content: `${data.item.option_desc[0]}`,
                      placement: 'right-center',
                      classes: ['light'],
                      delay: {
                        show: 500,
                        hide: 300
                      },
                      offset: '5'
                    }"
                  ></i>
                </div>
                <div v-else :key="`field-${index}`">
                  <span
                    class="font-light"
                    :class="{ 'font-semi-bold': data.item.option.toLowerCase().includes('total') }"
                  >
                    {{ data.item[field.key] | formatPayment }}
                  </span>
                  <i
                    v-if="data.item[`${field.key}_desc`]"
                    class="fa fa-info-circle text-info mx-1"
                    v-tooltip="{
                      content: data.item[`${field.key}_desc`],
                      placement: 'bottom-center',
                      classes: ['light'],
                      delay: {
                        show: 500,
                        hide: 300
                      },
                      offset: '5'
                    }"
                  ></i>
                </div>
              </template>
            </b-table>
            <div v-else class="control-loader"></div>
            <template #overlay>
              <running-loader loading></running-loader>
            </template>
          </b-overlay>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatPayment } from '@/constants/config'
import SingleSelectionFilter from '@/components/Selections/SingleSelectionFilter.vue'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
export default {
  components: {
    SingleSelectionFilter,
    ScaleLoader
  },
  props: {
    loadingWeeks: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loader: {
        color: '#2d7281'
      },
      reloadTable: 0,
      selectedWeek: null,
      skipLoading: false,
      teamWeeks: []
    }
  },
  methods: {
    ...mapActions(['fetchRoundBreakdownTable']),
    async forceUpdateRoundBreakdownTable() {
      if (this.currentSimulation && this.selectedWeek) {
        this.skipLoading = true
        await this.getRoundBreakdownTable(this.currentSimulation.id, this.selectedWeek.id)
        this.skipLoading = false
      }
    },
    async getRoundBreakdownTable(simulationId, weekId) {
      await this.fetchRoundBreakdownTable({ simulationId, weekId })
    },
    refreshTable() {
      this.reloadTable += 1
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      let option = item.option.toLowerCase()
      if (option.includes('total')) {
        return option === 'total profit' ? 'table-light-1' : 'table-light-2'
      }
    }
  },
  computed: {
    ...mapGetters(['allTeams', 'allTeamWeeks', 'currentSimulation', 'roundBreakdown']),
    ...mapGetters('loading', ['loadingRoundBreakdownTable']),
    allWeeks() {
      let all = {
        id: 0,
        name: 'All weeks'
      }
      let weeks = this.allTeamWeeks.team_weeks.map((week) => {
        return {
          id: week.week_id,
          name: week.week_name
        }
      })

      weeks.unshift(all)
      this.selectedWeek = all

      return weeks
    }
  },
  filters: {
    formatPayment: function (value) {
      return formatPayment(value)
    }
  },
  watch: {
    allTeamWeeks(newValue) {
      if (newValue.length > 0) {
        let allTeamWeeks = newValue.team_weeks
        if (newValue) {
          this.teamWeeks = allTeamWeeks.map((week) => {
            return {
              id: week.id,
              name: week.week_name
            }
          })

          let activeTeamWeek = allTeamWeeks.find((w) => w.status_id === 2)

          this.selectedWeek = {
            id: activeTeamWeek.id,
            name: activeTeamWeek.week_name
          }
        }
      }
    },
    selectedWeek(newValue) {
      if (newValue && this.currentSimulation) {
        this.getRoundBreakdownTable(this.currentSimulation.id, newValue.id)
      }
    }
  }
}
</script>
