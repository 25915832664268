<template>
  <b-form-group
    class="base-selection"
    label-for="input"
    :label="label"
    :label-class="{ 'required-field': required }"
  >
    <b-overlay :show="loading" rounded="sm">
      <b-dropdown
        class="w-100"
        no-caret
        toggle-class="p-0"
        variant="empty"
        :disabled="disabled"
        :menu-class="`px-0 search-item-menu a-shadow-sm ${menuClass}`"
      >
        <template slot="button-content">
          <b-form-input
            autocomplete="off"
            readonly
            v-model="selected[itemValue]"
            v-tooltip="{
              content: error,
              show: validate && v.$error,
              trigger: 'manual',
              placement: 'bottom',
              classes: ['danger']
            }"
            :disabled="disabled"
            :placeholder="placeholder"
            :state="validate && v.$error"
            @input="v.$touch()"
          />
        </template>
        <b-dropdown-group>
          <b-form-input
            v-if="searchable"
            v-model="searchItem"
            key="search-input"
            type="text"
            placeholder="Search item..."
            autocomplete="off"
            class="search-item-input mb-2"
          />
          <vue-perfect-scrollbar
            v-if="filteredItems"
            class="scroll px-0 mx-0"
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
          >
            <b-dropdown-item
              v-for="(item, index) in filteredItems"
              link-class="d-flex px-3 py-2"
              :key="`item-${index}`"
              :active="setItemActive(item[itemKey])"
              @click="onItemClick(item)"
            >
              <span>{{ item.name }}</span>
              <i
                v-if="setItemActive(item[itemKey])"
                class="align-self-center fas fa-circle-check ml-auto text-primary"
              ></i>
            </b-dropdown-item>
          </vue-perfect-scrollbar>
        </b-dropdown-group>
      </b-dropdown>
      <template #overlay>
        <pulse-loader :loading="loading" :color="fill" size="10px"></pulse-loader>
      </template>
    </b-overlay>
  </b-form-group>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
export default {
  components: {
    PulseLoader
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    label: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    menuClass: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Your input'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    v: {
      type: Object,
      required: false
    },
    validate: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fill: variables.primaryColor,
      searchItem: '',
      selectedItem: null
    }
  },
  methods: {
    onItemClick(item) {
      this.selectedItem = item
      this.$emit('input', item)
    },
    setItemActive(key) {
      return this.selected ? key === this.selected[this.itemKey] : false
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
          ? this.value
          : {
              [this.itemValue]: ''
            }
      }
    },
    filteredItems() {
      return this.options.filter((item) => {
        return item.name.toLowerCase().includes(this.searchItem.toLowerCase())
      })
    }
  }
}
</script>
