<template>
  <div class="score-centre">
    <dashboard-layout hideTeam hideWeekDay>
      <template #header>
        <h4 class="p-0 mb-0 align-self-center mr-3">Score Centre</h4>
      </template>
      <template #body>
        <h6>
          <b-badge variant="light" class="w-100">Overall Result</b-badge>
        </h6>
        <overall-result-chart :loading="loadingScoreCentreOverallCharts"></overall-result-chart>
        <h6>
          <b-badge variant="light" class="w-100">Weekly Result</b-badge>
        </h6>
        <weekly-result-chart :loading="loadingScoreCentreWeeklyCharts"></weekly-result-chart>
        <h6>
          <b-badge variant="light" class="w-100">Results Summary</b-badge>
        </h6>
        <round-break-down-table
          ref="round-break-down-table"
          :loadingWeeks="loadingTeamWeeks"
        ></round-break-down-table>
      </template>
    </dashboard-layout>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { managementMixin } from '@/mixins/managementMixin'
import { pusherMixin } from '@/mixins/pusherMixin'
import BaseChart from '@/components/ScoreCentre/BaseChart.vue'
import DashboardLayout from '@/components/Layouts/DashboardLayout'
import OverallResultChart from '@/components/ScoreCentre/OverallResultChart.vue'
import RoundBreakDownTable from '@/components/ScoreCentre/RoundBreakDownTable.vue'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
import WeeklyResultChart from '@/components/ScoreCentre/WeeklyResultChart.vue'
export default {
  components: {
    BaseChart,
    DashboardLayout,
    OverallResultChart,
    RoundBreakDownTable,
    ScaleLoader,
    WeeklyResultChart
  },
  mixins: [managementMixin, pusherMixin],
  data() {
    return {
      loader: {
        color: '#2d7281'
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchAllProcessedScores',
      'fetchAllTeamWeeks',
      'fetchRoundBreakdownTable',
      'fetchOverallResultsCharts',
      'fetchWeeklyResultsCharts'
    ]),
    async getChartData(simulationId, reload = true) {
      await Promise.all([
        this.fetchWeeklyResultsCharts({ simulationId, reload }),
        this.fetchOverallResultsCharts({ simulationId, reload })
      ])
    },
    async getTeamWeeks() {
      const teamId = this.currentUser.teamId ? this.currentUser.teamId : 1
      await this.fetchAllTeamWeeks(teamId)
    },
    async initiateVmbListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`score-centre.${this.currentSimulation.id}`)
      await channel.bind(
        'score-centre',
        (data) => {
          if (data) {
            this.getChartData(this.currentSimulation.id, false)
            if (this.$refs['round-break-down-table']) {
              this.$refs['round-break-down-table'].forceUpdateRoundBreakdownTable()
            }
          }
        },
        channel.unbind()
      )
    }
  },
  computed: {
    ...mapGetters([
      'allTeamProcessedScores',
      'allTeamWeeks',
      'currentSimulation',
      'currentUser',
      'overallAccuracy',
      'overallProfitability',
      'overallVolume',
      'roundBreakdown',
      'weeklyAccuracy',
      'weeklyProfitability',
      'weeklyVolume'
    ]),
    ...mapGetters('loading', [
      'loadingScoreCentreOverallCharts',
      'loadingScoreCentreRoundBreakdownTable',
      'loadingScoreCentreWeeklyCharts',
      'loadingTeamWeeks'
    ])
  },
  watch: {
    currentSimulation: {
      async handler(newValue) {
        if (newValue) {
          await Promise.all([
            this.getChartData(newValue.id),
            this.getTeamWeeks(),
            this.initiateVmbListener()
          ])
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.leaveChannels()
  }
}
</script>

<style lang="scss" scoped>
.stats-table {
  height: 400px;
}

.v-spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100px;
  text-align: center;
  opacity: 0.8;
}

.table.b-table > thead > tr > .table-b-table-default {
  border: none;
}

.table thead th {
  border: none;
}
</style>
