<template>
  <div class="work-allocations-table">
    <b-card class="table-card">
      <b-form @submit.prevent="onWorkAllocationSubmit">
        <div class="header d-flex mb-2">
          <h4 class="m-0 align-self-center">Work Allocations</h4>
          <base-single-selection
            badge
            class="ml-2 align-self-center"
            itemKey="id"
            itemName="week_name"
            placeholder="Select week"
            v-model="selectedWeek"
            :loading="loading"
            :options="teamWeeks"
          >
          </base-single-selection>
          <div class="ml-auto d-flex align-self-center">
            <div class="align-self-center mr-3">
              <b-dropdown
                variant="empty"
                toggle-class="p-0"
                no-caret
                right
                menu-class="mt-2 btn-shadow"
                class="align-self-center"
                :disabled="loadingWorkAllocations || selectedWeek.status_id === 3"
              >
                <template slot="button-content">
                  <div
                    :key="quickPolicyPreference"
                    class="policy-icon animate__animated animate__bounceIn"
                  >
                    <img :src="getPolicyPreference(quickPolicyPreference)" alt="" />
                  </div>
                </template>
                <b-dropdown-item
                  v-for="(policy, index) in policyPreferences"
                  :key="`policy-option-${index}`"
                  class="d-flex"
                  @click="onQuickPolicyOptionClick(policy)"
                >
                  <div class="d-flex">
                    <div class="content-img-bg align-self-center mr-2">
                      <img :src="policy.img" alt="" />
                    </div>
                    <div class="align-self-center">
                      <p class="mx-1 mb-1">{{ policy.label }}</p>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="align-self-center">
              <quick-work-allocation-selector
                v-model="quickWorkAllcation"
                :disabled="loadingWorkAllocations || selectedWeek.status_id === 3"
                class="quick-allocations align-self-center"
              ></quick-work-allocation-selector>
            </div>
          </div>
        </div>
        <b-overlay
          :show="loading || loadingWorkAllocations"
          class="overlay"
          rounded="sm"
          bg-color="white"
        >
          <b-table
            :fields="workAllocationsTable.fields"
            :items="workAllocationsTableData"
            v-model="visibleRows"
            hover
            class="mb-0"
          >
            <template #cell(user_name)="data">
              <b-avatar :src="data.item.avatar" size="35"></b-avatar>
              <span class="ml-2">{{ data.item.user_name }}</span>
            </template>
            <template #cell(allocated)="data">
              <transition
                :enter-active-class="
                  setPendingAllocationsTransition(
                    data.item.allocated,
                    data.item.add,
                    data.item.remove,
                    data.item
                  ).enter
                "
                :leave-active-class="
                  setPendingAllocationsTransition(
                    data.item.allocated,
                    data.item.add,
                    data.item.remove,
                    data.item
                  ).leave
                "
              >
                <p
                  :key="`lodged-${data.item.allocated}-${data.item.add}-${data.item.remove}`"
                  class="m-0 float-text position-absolute"
                >
                  <b-avatar
                    :variant="
                      setPendingAllocationsVariant(
                        data.item.allocated,
                        data.item.add,
                        data.item.remove
                      )
                    "
                    :text="
                      setPendingAllocationsText(
                        data.item.allocated,
                        data.item.add,
                        data.item.remove
                      )
                    "
                    size="30"
                    class="mr-1"
                  >
                  </b-avatar>
                  <i
                    class="fa"
                    :class="
                      setPendingAllocationsTrend(
                        data.item.allocated,
                        data.item.add,
                        data.item.remove
                      )
                    "
                  ></i>
                </p>
              </transition>
            </template>
            <template #cell(lodged)="data">
              <transition
                enter-active-class="animate__animated animate__fadeInUp"
                leave-active-class="animate__animated animate__fadeOutUp"
              >
                <p :key="`lodged-${data.item.lodged}`" class="m-0 float-text position-absolute">
                  <b-avatar
                    variant="white"
                    :text="data.item.lodged === 0 ? '0' : data.item.lodged.toString()"
                    size="30"
                  ></b-avatar>
                </p>
              </transition>
            </template>
            <template #cell(remaining)="data">
              <transition
                :enter-active-class="
                  setPendingAllocationsTransition(
                    data.item.remaining,
                    data.item.add,
                    data.item.remove,
                    data.item
                  ).enter
                "
                :leave-active-class="
                  setPendingAllocationsTransition(
                    data.item.remaining,
                    data.item.add,
                    data.item.remove,
                    data.item
                  ).leave
                "
              >
                <p
                  :key="`remaining-${data.item.remaining}-${data.item.add}-${data.item.remove}`"
                  class="m-0 float-text position-absolute"
                >
                  <b-avatar
                    :variant="
                      setPendingAllocationsVariant(
                        data.item.remaining,
                        data.item.add,
                        data.item.remove
                      )
                    "
                    :text="
                      setPendingAllocationsText(
                        data.item.remaining,
                        data.item.add,
                        data.item.remove
                      )
                    "
                    size="30"
                    class="mr-1"
                  >
                  </b-avatar>
                  <i
                    class="fa"
                    :class="
                      setPendingAllocationsTrend(
                        data.item.remaining,
                        data.item.add,
                        data.item.remove
                      )
                    "
                  ></i>
                </p>
              </transition>
            </template>
            <template #cell(add)="data">
              <div class="d-flex justify-content-center">
                <div
                  class="d-flex policy-input-wrapper"
                  :class="{ disabled: data.item.removeTouch || selectedWeek.status_id === 3 }"
                >
                  <div class="policy-icon-wrapper">
                    <b-dropdown
                      variant="empty"
                      toggle-class="p-0"
                      menu-class="mt-2 btn-shadow"
                      boundary="window"
                      no-caret
                      right
                      :disabled="loadingWorkAllocations || selectedWeek.status_id === 3"
                    >
                      <template slot="button-content">
                        <div
                          :key="`user-policy-${data.item.user_id}-${data.item.policy_preference}`"
                          class="policy-icon animate__animated animate__bounceIn"
                        >
                          <img :src="getPolicyPreference(data.item.policy_preference)" alt="" />
                        </div>
                      </template>
                      <b-dropdown-item
                        v-for="(policy, index) in policyPreferences"
                        :key="`policy-option-${index}`"
                        class="d-flex"
                        @click="onPolicyOptionClick(data.item, policy)"
                      >
                        <div class="d-flex">
                          <div class="content-img-bg align-self-center mr-2">
                            <img :src="policy.img" alt="" />
                          </div>
                          <div class="align-self-center">
                            <p class="mx-1 mb-1">{{ policy.label }}</p>
                          </div>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <b-form-input
                    v-model="data.item.add"
                    type="number"
                    min="0"
                    oninput="validity.valid||(value='');"
                    :disabled="data.item.removeTouch || selectedWeek.status_id === 3"
                    @input="onAllocationsInput(data.item, 1)"
                    @focus="$event.target.select()"
                    class="px-2 py-1 work-input text-center align-self-center"
                  ></b-form-input>
                </div>
                <i
                  :key="`add-error-check-${data.item.id}-${data.item.addError}`"
                  class="fa fa-exclamation-circle work-input-error align-self-center ml-2 animate__animated animate__bounceIn"
                  :class="data.item.addError ? 'text-danger' : 'text-light'"
                  v-tooltip="{
                    content: data.item.addError,
                    placement: 'bottom',
                    classes: ['light'],
                    offset: '5'
                  }"
                ></i>
              </div>
            </template>
            <template #cell(remove)="data">
              <div class="d-flex justify-content-center">
                <div
                  class="d-flex policy-input-wrapper"
                  :class="{ disabled: data.item.addTouch || selectedWeek.status_id === 3 }"
                >
                  <div class="policy-icon-wrapper">
                    <b-dropdown
                      variant="empty"
                      toggle-class="p-0 disabled-policy"
                      no-caret
                      disabled
                    >
                      <template slot="button-content">
                        <div class="policy-icon">
                          <img :src="getPolicyPreference('random')" alt="" />
                        </div>
                      </template>
                    </b-dropdown>
                  </div>
                  <b-form-input
                    v-model="data.item.remove"
                    type="number"
                    min="0"
                    oninput="validity.valid||(value='');"
                    :disabled="data.item.addTouch || selectedWeek.status_id === 3"
                    @input="onAllocationsInput(data.item, 2)"
                    @focus="$event.target.select()"
                    class="px-2 py-1 work-input text-center align-self-center"
                  ></b-form-input>
                </div>
                <i
                  :key="`add-error-check-${data.item.id}-${data.item.removeError}`"
                  class="fa fa-exclamation-circle work-input-error align-self-center ml-2 animate__animated animate__bounceIn"
                  :class="data.item.removeError ? 'text-danger' : 'text-light'"
                  v-tooltip="{
                    content: data.item.removeError,
                    placement: 'bottom',
                    classes: ['light'],
                    offset: '5'
                  }"
                ></i>
              </div>
            </template>
          </b-table>

          <b-table
            :fields="workAllocationsTableFooter.fields"
            :items="workAllocationsTableFooter.data"
            hover
            class="total-table"
          >
            <template #cell(allocated)="data">
              <h6 class="m-0">
                <b-badge pill variant="outline-info">{{ totalAllocated }}</b-badge>
              </h6>
            </template>
            <template #cell(lodged)="data">
              <h6 class="m-0">
                <b-badge pill variant="outline-info">{{ totalLodged }}</b-badge>
              </h6>
            </template>
            <template #cell(remaining)="data">
              <h6 class="m-0">
                <b-badge pill variant="outline-info">{{ totalRemaining }}</b-badge>
              </h6>
            </template>
          </b-table>

          <template #overlay>
            <running-loader loading></running-loader>
          </template>
        </b-overlay>
        <div class="d-flex action-button">
          <div class="align-self-center ml-auto">
            <processing-button
              key="btn-work"
              variant="primary"
              size="sm"
              label="Save"
              class="align-self-center btn-shadow ml-auto"
              :processing="processingWork"
              :disabled="!hasEditedWork || selectedWeek.status_id === 3 || hasAllocationError"
              :error="hasAllocationError"
            ></processing-button>
          </div>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { pusherMixin } from '@/mixins/pusherMixin'
import {
  workAllocationsTable,
  workAllocationsTableFooter,
  policyPreferences,
  quickAllocations,
  maxAllocations
} from './config'
import BaseSingleSelection from '@/components/Selections/BaseSingleSelection.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
import QuickWorkAllocationSelector from './QuickWorkAllocationSelector.vue'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
import SingleSelectionFilter from '@/components/Selections/SingleSelectionFilter.vue'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
import WorkAllocationBaseInput from './WorkAllocationBaseInput.vue'
export default {
  components: {
    BaseSingleSelection,
    ProcessingButton,
    QuickWorkAllocationSelector,
    ScaleLoader,
    SingleSelectionFilter,
    WorkAllocationBaseInput
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedWeek: this.createNewSelectedWeek(),
      quickAllocations,
      quickWorkAllcation: 0,
      workAllocationsTable,
      workAllocationsTableFooter,
      workVolume: 0,
      workAllocationsTableData: this.initiateDefaultTableData(),
      teamWeeks: [],
      loadingWorkAllocations: true,
      fill: variables.primaryColor,
      policyPreferences,
      policyPreference: null,
      quickPolicyPreference: 'random',
      editedWorkAllocations: [],
      editedWorkAllocation: this.createNewWorkAllocation(),
      processingWork: false,
      hasInputChanged: false,
      errorAllocations: [],
      maxAllocations,
      visibleRows: []
    }
  },
  mixins: [pusherMixin],
  methods: {
    ...mapActions([
      'fetchTeamWeekWorkAllocations',
      'fetchTeamWeekWorkAllocationsStatus',
      'submitWorkAllocations'
    ]),
    initiateDefaultTableData() {
      return workAllocationsTable.data
    },
    createNewWorkAllocation() {
      return {
        userId: null,
        policyPreference: null,
        add: 0,
        remove: 0
      }
    },
    createNewSelectedWeek() {
      return {
        id: -1,
        week_name: '',
        status_id: 0
      }
    },
    async setSelectedWeek(activeTeamWeek) {
      this.selectedWeek.id = activeTeamWeek.id
      this.selectedWeek.week_name = activeTeamWeek.week_name
      this.selectedWeek.status_id = activeTeamWeek.status_id
    },
    async getTeamWeekWorkAllocations(payload) {
      this.loadingWorkAllocations = true
      await this.fetchTeamWeekWorkAllocations(payload)
      let workData = await _.cloneDeep(this.allTeamWeekWorkAllocations.data)
      this.workAllocationsTableData = await _.map(workData, (w) =>
        _.extend(
          { add: 0, remove: 0, addError: '', removeError: '', addTouch: false, removeTouch: false },
          w
        )
      )
      this.policyPreference = this.allTeamWeekWorkAllocations.policyPreference
      this.editedWorkAllocations = []
      this.loadingWorkAllocations = false
    },
    async getTeamWeekWorkAllocationsStatus() {
      await this.fetchTeamWeekWorkAllocationsStatus(this.activeTeamWeek.id)
    },
    getPolicyPreference(policy) {
      return this.policyPreferences.find((p) => p.type === policy).img
    },
    onQuickPolicyOptionClick(policy) {
      let allWork = this.workAllocationsTableData
      allWork.map((w) => (w.policy_preference = policy.type))
      this.quickPolicyPreference = policy.type
    },
    async onQuickAllocationClick(allocation) {
      let allWork = await this.workAllocationsTableData
      await allWork.forEach((w) => {
        // only add quick allocations if removals not applied
        if (Number(w.remove) === 0) {
          w.add = allocation
          this.onAllocationsInput(w, 1)
        }
      })
    },
    onPolicyOptionClick(item, policy) {
      item.policy_preference = policy.type
    },
    onAllocationsInput(item, type) {
      // validate addition
      if (type === 1 && Number(item.add) >= 0) {
        item.addTouch = true
        if (
          Number(item.allocated) + Number(item.add) > this.maxAllocations &&
          Number(item.add) !== 0
        ) {
          item.addError = 'Exceeds maximum work allocation limit for the week'
          this.checkErrorAllocations(item.user_id, 1)
          return
        } else {
          this.checkErrorAllocations(item.user_id, 2)
        }
      }

      // validate removal
      if (type === 2 && Number(item.remove) >= 0) {
        item.removeTouch = true
        if (Number(item.remaining) - Number(item.remove) < 0 && Number(item.remove) !== 0) {
          item.removeError = 'Exceeds total remaining work'
          this.checkErrorAllocations(item.user_id, 1)
          return
        } else {
          this.checkErrorAllocations(item.user_id, 2)
        }
      }

      if (_.isEmpty(item.add.toString())) {
        item.add = 0
        item.addTouch = false
        item.removeTouch = false
      }

      if (_.isEmpty(item.remove.toString())) {
        item.remove = 0
        item.removeTouch = false
        item.addTouch = false
      }

      if (Number(item.add) === 0 && Number(item.remove) === 0) {
        item.addTouch = false
        item.removeTouch = false
      }

      item.addError = ''
      item.removeError = ''
    },
    checkErrorAllocations(id, option) {
      let existed = this.errorAllocations.includes(id)
      if (option === 1) {
        if (!existed) {
          this.errorAllocations.push(id)
        }
      } else {
        if (existed) {
          const index = this.errorAllocations.indexOf(id)
          this.errorAllocations.splice(index, 1)
        }
      }
    },
    setPendingAllocationsVariant(original, add, remove) {
      let result = Number(original) + Number(add) - Number(remove)
      let variant = 'white'
      if (result > Number(original)) variant = 'info'
      else if (result < Number(original)) variant = 'danger'
      else variant = 'white'
      return variant
    },
    setPendingAllocationsTransition(original, add, remove, item) {
      let result = Number(original) + Number(add) - Number(remove)
      let transition = {
        enter: 'animate__animated animate__fadeIn',
        leave: 'animate__animated animate__fadeOut'
      }
      if (result > Number(original)) {
        transition.enter = 'animate__animated animate__fadeInUp'
        transition.leave = 'animate__animated animate__fadeOutUp'
      } else if (result < Number(original)) {
        transition.enter = 'animate__animated animate__fadeInDown'
        transition.leave = 'animate__animated animate__fadeOutDown'
      } else if (item.isRemained) {
        transition.enter = 'animate__animated animate__fadeInDown'
        transition.leave = 'animate__animated animate__fadeOutDown'
      } else {
        transition.enter = 'animate__animated animate__fadeIn'
        transition.leave = 'animate__animated animate__fadeOut'
      }
      return transition
    },
    setPendingAllocationsText(original, add, remove) {
      let result = Number(original) + Number(add) - Number(remove)
      return result < 0 ? '0' : result.toString()
    },
    setPendingAllocationsTrend(original, add, remove) {
      let result = Number(original) + Number(add) - Number(remove)
      let cssClass = ''

      if (result > Number(original)) {
        cssClass = 'fa-arrow-up text-info'
      } else if (result < Number(original)) {
        cssClass = 'fa-arrow-down text-danger'
      } else {
        cssClass = ''
      }
      return cssClass
    },
    async onWorkAllocationSubmit() {
      let allWork = this.workAllocationsTableData
      let editedWork = allWork.filter((w) => w.add > 0 || w.remove > 0)

      editedWork.forEach((w) => {
        let work = this.createNewWorkAllocation()
        work.userId = w.user_id
        work.policyPreference = w.policy_preference
        work.add = w.add
        work.remove = w.remove
        this.editedWorkAllocations.push(work)
      })

      let payload = {
        teamWeekId: this.selectedWeek.id,
        allocation: this.editedWorkAllocations
      }

      this.processingWork = true
      await this.submitWorkAllocations(payload)
      this.processingWork = false
      await this.resetTable()

      // validate work allocations status
      if (this.selectedWeek.id === this.activeTeamWeek.id) {
        await this.getTeamWeekWorkAllocationsStatus()
      }
    },
    async resetTable() {
      this.editedWorkAllocations = []
      let teamId = this.allTeamWeekDays.team_id
      let weekId = this.selectedWeek.id
      let payload = {
        teamId: teamId,
        teamWeekId: weekId
      }
      await this.getTeamWeekWorkAllocations(payload)
    },
    async updateWorkAllocationsByUserId(userId) {
      let userWorkAllocations = this.workAllocationsTableData.find((wa) => wa.user_id === userId)
      userWorkAllocations.lodged += 1
      userWorkAllocations.remaining -= 1
      userWorkAllocations.isRemained = true
    },
    async initiateNewSubmittedListener() {
      if (this.allTeamWeekDays === null && this.allTeamWeekDays.team_id === null) {
        return
      }

      let pusher = window.Pusher
      await pusher.subscribe(`new_submitted_team.${this.allTeamWeekDays.team_id}`)
      await pusher.bind('new_submitted_team', (e) => {
        const userId = e.user
        this.updateWorkAllocationsByUserId(userId)
      })
    },
    async leaveCurrentChannel(teamId) {
      // unsuscribe channel and unbind event
      if (teamId) {
        const pusher = window.Pusher
        await pusher.unsubscribe(`new_submitted_team.${teamId}`)
        await pusher.unbind('new_submitted_team')
      }
    }
  },
  computed: {
    ...mapGetters(['allTeamWeekDays', 'allTeamWeekWorkAllocations']),
    activeTeamWeeks() {
      return this.allTeamWeekDays.team_weeks
    },
    activeTeamWeek() {
      return this.activeTeamWeeks.find((tw) => tw.status_id === 2)
    },
    quickAssignedOptions() {
      let max = this.quickAllocations.max
      let step = this.quickAllocations.step
      return _.range(5, max + step, step)
    },
    totalAllocated() {
      return this.visibleRows.reduce((accum, item) => {
        return accum + item.allocated
      }, 0)
    },
    totalLodged() {
      return this.visibleRows.reduce((accum, item) => {
        return accum + item.lodged
      }, 0)
    },
    totalRemaining() {
      return this.visibleRows.reduce((accum, item) => {
        return accum + item.remaining
      }, 0)
    },
    hasEditedWork() {
      let allWork = this.workAllocationsTableData
      let editedWork = allWork.filter((w) => w.add > 0 || w.remove > 0)
      return editedWork.length > 0 ? true : false
    },
    hasAllocationError() {
      return this.errorAllocations.length > 0 ? true : null
    }
  },
  watch: {
    selectedWeek: {
      async handler(newValue) {
        this.errorAllocations = []
        this.workAllocationsTableData = this.initiateDefaultTableData()
        let teamId = this.allTeamWeekDays.team_id
        let weekId = newValue.id
        let payload = {
          teamId: teamId,
          teamWeekId: weekId
        }
        await this.getTeamWeekWorkAllocations(payload)
      },
      deep: true
    },
    quickWorkAllcation(newValue) {
      if (newValue) {
        this.onQuickAllocationClick(newValue)
        this.quickWorkAllcation = 0
      }
    },
    allTeamWeekDays: {
      async handler(newValue, oldValue) {
        if (newValue) {
          this.teamWeeks = await this.activeTeamWeeks.map((tw) => {
            return {
              id: tw.id,
              week_name: tw.week_name,
              status_id: tw.status_id
            }
          })
        }

        // leave old team channel
        if (oldValue && oldValue.team_id) {
          this.leaveCurrentChannel(oldValue.team_id)
        }

        await this.initiateNewSubmittedListener()

        // check if day changed
        this.activeTeamWeek.id === this.selectedWeek.id
          ? await this.resetTable()
          : await this.setSelectedWeek(this.activeTeamWeek)
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.leaveChannels()
  }
}
</script>

<style lang="scss" scoped>
.overlay {
  min-height: 500px;
}
</style>
