<template>
  <div class="d-flex flex-column justify-content-center align-items-center h-100 text-muted-light">
    <i class="fal" :class="`fa-${icon} ${setIconSize(iconSize)}`"></i>
    <h6 class="font-light mt-4 mx-auto text-muted-light" v-html="text"></h6>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'copy'
    },
    iconSize: {
      type: String,
      default: 'md'
    },
    text: {
      type: String,
      default: 'No item exists.'
    }
  },
  methods: {
    setIconSize(size) {
      switch (size) {
        case 'xs':
          return 'fa-2x'
        case 'sm':
          return 'fa-3x'
        case 'md':
          return 'fa-4x'
        case 'lg':
          return 'fa-6x'
        case 'xl':
          return 'fa-8x'
        default:
          return 'fa-4x'
      }
    }
  }
}
</script>
