<template>
  <div class="d-flex py-3 game-financials-item animate__animated animate__fadeIn">
    <p class="m-0 w-20 w-sm-100 align-self-center text-center">
      {{ gameFinancial.description }}
    </p>
    <p class="m-0 w-20 w-sm-100 align-self-center text-center">
      {{ Number(gameFinancial.amount) | formatPayment }}
    </p>
    <div class="m-0 w-20 w-sm-100 align-self-center text-center">
      <b-badge
        :variant="gameFinancial.status === 'delete' ? 'light' : setTypeVariant(gameFinancial.type)"
        pill
      >
        {{ _.capitalize(gameFinancial.type) }}
      </b-badge>
    </div>
    <p class="m-0 w-15 w-sm-100 align-self-center text-center">
      <b-badge :variant="gameFinancial.status === 'delete' ? 'light' : 'outline-info'">
        {{ gameFinancial.team_week_name }}
      </b-badge>
    </p>
    <p class="m-0 w-15 w-sm-100 align-self-center text-center">
      <b-badge :variant="gameFinancial.status === 'delete' ? 'light' : 'outline-danger'">
        {{ gameFinancial.team_week_day_name }}
      </b-badge>
    </p>
    <div
      v-if="gameFinancial.status === 'delete'"
      class="mb-0 w-10 w-sm-100 d-flex justify-content-center game-financials-actions"
    >
      <i
        class="fa fa-undo align-self-center text-danger"
        @click="onGameFinancialRestore"
        v-tooltip="{
          content: `Restore item`,
          placement: 'bottom',
          classes: ['light'],
          offset: 5
        }"
      ></i>
    </div>
    <div v-else class="mb-0 w-10 w-sm-100 d-flex justify-content-center game-financials-actions">
      <i
        class="fal fa-edit align-self-center mr-2 text-primary"
        @click="onGameFinancialEdit"
        v-tooltip="{
          content: `Edit item`,
          placement: 'bottom',
          classes: ['light'],
          offset: 15
        }"
      ></i>
      <i
        class="fal fa-trash align-self-center text-danger"
        @click="onGameFinancialDelete"
        v-tooltip="{
          content: `Delete item`,
          placement: 'bottom',
          classes: ['light'],
          offset: 15
        }"
      ></i>
    </div>
  </div>
</template>

<script>
import { helperMixin } from '@/mixins/helperMixin'
import { formatPayment } from '@/constants/config'
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [helperMixin],
  filters: {
    formatPayment(value) {
      return formatPayment(value)
    }
  },
  methods: {
    setTypeVariant(type) {
      return type.toLowerCase().trim() === 'debit' ? 'warning' : 'info'
    },
    onGameFinancialEdit() {
      this.$emit('edited')
    },
    onGameFinancialDelete() {
      this.$emit('deleted')
    },
    onGameFinancialRestore() {
      this.$emit('restored')
    }
  },
  computed: {
    gameFinancial: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.game-financials-actions {
  i {
    font-size: 1.2rem;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
