<template>
  <div class="overall-result-chart">
    <b-overlay :show="loading" rounded="sm" bg-color="white">
      <!-- <template v-if="data && data.datasets && data.datasets.length"> -->
      <div v-if="isBarChart">
        <bar-shadow-chart
          v-if="shadow"
          :data="data"
          :height="height"
          :options="options"
        ></bar-shadow-chart>
        <bar-chart v-else :chart-data="data" :options="options" :height="height" />
      </div>
      <div v-else>
        <line-shadow-chart
          v-if="shadow"
          :data="data"
          :height="height"
          :options="options"
        ></line-shadow-chart>
        <line-chart v-else :chart-data="data" :options="options" :height="height" />
      </div>
      <!-- </template> -->
      <template #overlay>
        <running-loader loading></running-loader>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BarChart from '@/components/Charts/Bar'
import BarShadowChart from '@/components/Charts/BarShadow.vue'
import LineChart from '@/components/Charts/Line.vue'
import LineShadowChart from '@/components/Charts/LineShadow.vue'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
export default {
  props: {
    chartType: {
      type: String,
      default: 'bar'
    },
    data: {
      type: Object,
      default: null
    },
    height: {
      type: Number,
      default: 280
    },
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {}
    },
    shadow: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BarChart,
    BarShadowChart,
    LineChart,
    LineShadowChart,
    ScaleLoader
  },
  data() {
    return {
      loader: {
        color: '#2d7281'
      }
    }
  },
  computed: {
    isBarChart() {
      let result = true
      if (this.chartType === 'line') {
        result = false
      }

      return result
    }
  }
}
</script>
