export const pageVisibilityMixin = {
  data() {
    return {
      pageHiddenTimeStamp: 0,
      pageVisibleTimeStamp: 0,
      pageVisibilityTimeGap: 0
    }
  },
  methods: {
    visibilityChangeHandler(value) {
      const visibility = document.visibilityState
      if (visibility === 'hidden') {
        this.pageHiddenTimeStamp = value.timeStamp
      }

      if (visibility === 'visible') {
        this.pageVisibleTimeStamp = value.timeStamp
        this.pageVisibilityTimeGap = this.pageVisibleTimeStamp - this.pageHiddenTimeStamp
      }
    }
  },
  created() {
    document.addEventListener('visibilitychange', this.visibilityChangeHandler, false)
  },
  beforeDestroy() {
    this.pageHiddenTimeStamp = 0
    this.pageVisibleTimeStamp = 0
    this.pageVisibilityTimeGap = 0
    document.removeEventListener('visibilitychange', this.visibilityChangeHandler)
  }
}
