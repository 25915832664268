<template>
  <div class="team-week-day-control">
    <b-overlay :show="loading" rounded="sm" bg-color="white">
      <div v-if="!loading && allTeamWeekDays" class="day-control mx-auto">
        <b-row class="text-center mx-auto">
          <b-col cols="2" class="p-0">
            <b-avatar
              size="3.5em"
              :text="getNameInitials(activeTeamWeek.week_name)"
              variant="info"
              class="btn-shadow"
              v-tooltip="{
                content: activeTeamWeek.week_name,
                placement: 'bottom',
                classes: ['light'],
                offset: 10
              }"
            ></b-avatar>
          </b-col>
          <b-col
            cols="2"
            v-for="(day, index) in activeTeamWeek.team_week_days"
            :key="`day-${index}`"
            class="p-0"
          >
            <b-avatar
              size="3.5em"
              :text="getNameInitials(day.day_name)"
              class="btn-shadow"
              :class="{ 'animate__animated animate__fadeIn': day.status_id === 3 }"
              variant="new"
              :disabled="day.status_id !== 3"
              v-tooltip="{
                content: day.day_name,
                placement: 'bottom',
                classes: ['light'],
                offset: 10
              }"
            ></b-avatar>
          </b-col>
        </b-row>
        <div id="clock" class="text-center my-4 align-self-center">
          <timer
            :time="prettyTime"
            :warning="runningOutWarning"
            class="time"
            :class="{ pending: loadingGame || currentGame.status === 2 }"
          ></timer>
        </div>
        <div class="d-flex justify-content-center my-2">
          <overlay-loader :loading="loadingGame" class="w-50 mx-2">
            <template #content>
              <b-button
                size="md"
                :variant="!nextTeamWeek || !isFriday ? 'light' : 'info'"
                pill
                @click="onWeekClick"
                :disabled="!nextTeamWeek || !isFriday"
                class="btn-shadow w-100 d-flex action-button"
              >
                <span class="align-self-center">Next Week</span>
                <i class="simple-icon-action-redo align-self-center ml-auto"></i>
              </b-button>
            </template>
          </overlay-loader>
          <overlay-loader :loading="loadingGame" class="w-50 mx-2">
            <template #content>
              <b-button
                size="md"
                variant="danger"
                pill
                @click="onDayClick"
                :disabled="!nextTeamWeekDay"
                class="btn-shadow w-100 d-flex action-button"
              >
                <span class="align-self-center">Next Day</span>
                <i class="simple-icon-action-redo align-self-center ml-auto"></i>
              </b-button>
            </template>
          </overlay-loader>
        </div>
        <div class="d-flex justify-content-center my-3">
          <overlay-loader :loading="loadingGame" class="w-100 mx-2">
            <template #content>
              <b-button
                size="md"
                :variant="hasWorkAllocation && !loadingWorkStatus ? 'warning' : 'light'"
                pill
                @click="onGameClick"
                :disabled="gameTrigger.disabled || !hasWorkAllocation || loadingWorkStatus"
                class="btn-shadow w-100 d-flex action-button"
                :class="{
                  disabled: gameTrigger.disabled || !hasWorkAllocation || loadingWorkStatus
                }"
              >
                <span class="align-self-center">{{ gameTrigger.triggerName }}</span>
                <i class="simple-icon-rocket align-self-center ml-auto"></i>
              </b-button>
            </template>
          </overlay-loader>
        </div>
        <speech-bubble :show="!hasWorkAllocation" class="mx-3 mt-5">
          <template #content>
            Work must be assigned to team members before {{ activeTeamWeek.week_name }} can
            commence.
          </template>
        </speech-bubble>
        <speech-bubble :show="hasWorkAllocation && gameTrigger.status === 0" class="mx-3 mt-5">
          <template #content> {{ activeTeamWeekDay.day_name }} is ready to start. </template>
        </speech-bubble>
        <speech-bubble :show="hasWorkAllocation && gameTrigger.status === 1" class="mx-3 mt-5">
          <template #content>
            Waiting for {{ activeTeamWeekDay.day_name }} to finish.
            {{ activeTeamWeekDay.day_name }} will be completed in {{ time }}s.
          </template>
        </speech-bubble>
        <speech-bubble :show="hasWorkAllocation && gameTrigger.status === 2" class="mx-3 mt-5">
          <template #content>
            {{ activeTeamWeekDay.day_name }} has now finished. To proceed to the next round please
            click next day or week.
          </template>
        </speech-bubble>
        <speech-bubble :show="hasWorkAllocation && gameTrigger.status === -1" class="mx-3 mt-5">
          <template #content>
            <pulse-loader
              :loading="hasWorkAllocation && gameTrigger.status === -1"
              :color="fill"
              size="5px"
            ></pulse-loader>
          </template>
        </speech-bubble>
      </div>
      <div v-else class="control-loader"></div>
      <template #overlay>
        <running-loader loading></running-loader>
      </template>
    </b-overlay>

    <b-modal
      id="control-confirmation"
      ref="control-confirmation-modal"
      v-model="showModal"
      hide-header
      hide-footer
      @hide="onModalTrigger(false)"
    >
      <b-form @submit.prevent="onConfirmClick" class="text-center">
        <img class="modal-img my-4" :src="modalConent.img" alt />
        <h3 class="p-2">
          {{ modalConent.title }}
        </h3>
        <p class="font-weight-bold">
          {{ modalConent.subtitle }}
        </p>
        <div>
          <processing-button
            variant="warning"
            size="lg"
            label="Confirm"
            class="align-self-center btn-shadow my-2"
            :processing="processing"
          ></processing-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal
      id="welcome-modal"
      ref="welcome-modal"
      v-if="allTeamWeekDays"
      v-model="showWelcomeModal"
      hide-header
      hide-footer
      @hide="onWelcomeModalTrigger(false)"
      body-class="welcome-modal-body text-center"
    >
      <img
        class="modal-img my-4"
        src="/assets/img/svg/controls/party_outline_new.svg"
        alt="Welcome New Week Day"
      />
      <h2>Welcome to</h2>
      <h4 class="content text-uppercase">
        {{ activeTeamWeek.week_name }} - {{ activeTeamWeekDay.day_name }}
      </h4>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Timer from '@/components/Common/Timer'
import { helperMixin } from '@/mixins/helperMixin'
import { pageVisibilityMixin } from '@/mixins/pageVisibilityMixin'
import { teamWeekDayControlModal } from '@/data/admin'
import { gameDuration, gameStartGracePeriod } from '@/constants/config'
import OverlayLoader from '@/components/Common/OverlayLoader.vue'
import SpeechBubble from '@/components/Notification/SpeechBubble.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
import moment from 'moment'
export default {
  components: {
    Timer,
    OverlayLoader,
    SpeechBubble,
    PulseLoader,
    ProcessingButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startedAt: null,
      startTime: false,
      time: 0,
      timer: null,
      runningOutWarning: false,
      gameDuration,
      gameStartGracePeriod,
      modalData: teamWeekDayControlModal,
      modalDataId: 1,
      modalConent: {
        img: null,
        title: null,
        subtitle: null
      },
      localTimestamp: 0,
      processing: false,
      loadingGame: false,
      currentGame: this.createDefaultCurrentGame(),
      fill: variables.primaryColor,
      showModal: false,
      showWelcomeModal: false,
      loadingWorkStatus: false
    }
  },
  mixins: [helperMixin, pageVisibilityMixin],
  methods: {
    ...mapActions([
      'fetchTeamWeekWorkAllocationsStatus',
      'fetchGame',
      'submitNextWeek',
      'submitNextWeekDay',
      'triggerGame'
    ]),
    createDefaultCurrentGame() {
      return {
        status: -1,
        time: 0
      }
    },
    async getTeamWeekWorkAllocationsStatus() {
      this.loadingWorkStatus = true
      await this.fetchTeamWeekWorkAllocationsStatus(this.activeTeamWeek.id)
      this.loadingWorkStatus = false
    },
    async onWeekClick() {
      this.modalDataId = 1
      this.modalConent.img = '/assets/img/svg/controls/running_outline_info.svg'
      this.modalConent.title = `Are you sure you want to move to ${this.nextTeamWeek.week_name}?`
      this.modalConent.subtitle = `${this.activeTeamWeek.week_name} will be closed, and you will proceed to ${this.nextTeamWeek.week_name} Monday.`
      await this.onModalTrigger(true)
    },
    async onDayClick() {
      this.modalDataId = 2
      this.modalConent.img = '/assets/img/svg/controls/jogging_outline_new.svg'
      this.modalConent.title = `Are you sure you want to move to ${this.nextTeamWeekDay.day_name}?`
      this.modalConent.subtitle = `${this.activeTeamWeekDay.day_name} will be closed, and you will proceed to ${this.nextTeamWeekDay.day_name}.`
      this.onModalTrigger(true)
    },
    async onGameClick() {
      this.modalDataId = 3
      if (this.currentGame.status === 0) {
        this.modalConent.img = '/assets/img/svg/controls/startup_outline_new.svg'
        this.modalConent.title = `Are you sure you are ready to start work for ${this.activeTeamWeekDay.day_name}? `
        this.modalConent.subtitle = `Once started, the day will automatically close in 90 seconds.`
      }
      this.onModalTrigger(true)
    },
    onModalTrigger(value) {
      this.showModal = value
    },
    onWelcomeModalTrigger(value) {
      this.showWelcomeModal = value
    },
    getModalData(id) {
      return this.modalData.find((md) => md.id === id)
    },
    async onWeekSubmit() {
      this.loadingWorkStatus = true
      this.processing = true
      let teamWeekId = this.activeTeamWeek.id
      await this.submitNextWeek(teamWeekId)
      this.processing = false
      await this.onModalTrigger(false)
      await this.onWelcomeModalTrigger(true)
      await this.reset()
      await this.getGame()
      await this.getTeamWeekWorkAllocationsStatus()
    },
    async onDaySubmit() {
      let nextWeekDay = {
        teamWeekId: this.activeTeamWeek.id,
        weekDayId: this.nextTeamWeekDay.id,
        updatedBy: this.currentUser.title,
        completedAt: this.formatDate(new Date())
      }
      this.processing = true
      let updatedWeekDays = {
        teamWeekId: this.activeTeamWeek.id,
        pastWeekDayId: this.activeTeamWeekDay.id,
        nextWeekDayId: this.nextTeamWeekDay.id
      }
      let payload = {
        updatedWeekDays: updatedWeekDays,
        nextWeekDay: nextWeekDay
      }
      await this.submitNextWeekDay(payload)

      this.processing = false
      await this.onModalTrigger(false)
      await this.onWelcomeModalTrigger(true)
      await this.reset()
      await this.getGame()
    },
    async getGame() {
      let openTeamWeekDay = this.activeTeamWeekDay
      this.loadingGame = true
      await this.fetchGame(openTeamWeekDay.id)
      this.currentGame = this.game
      this.loadingGame = false
    },
    async onGameSubmit() {
      let openTeamWeekDay = this.activeTeamWeekDay
      this.processing = true
      await this.triggerGame({ id: openTeamWeekDay.id })
      this.processing = false
      this.localTimestamp = this.convertLocalTimestamp(new Date())
      let game = this.triggeredGame
      let timestampGap = this.localTimestamp - game.time
      let gameDuration = this.gameDuration
      let gameStartGracePeriod = this.gameStartGracePeriod
      this.currentGame.status = game.status
      if (game.status === 1) {
        if (timestampGap <= gameDuration && timestampGap >= gameStartGracePeriod) {
          this.startTimer(gameDuration - timestampGap)
        }
      }

      this.onModalTrigger(false)
    },
    startTimer(timestampGap) {
      this.startTime = true
      this.startedAt = new Date()
      this.time = timestampGap
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--
          } else {
            // auto close game
            this.onGameSubmit()
            this.reset()
            this.disabledGame = true
            this.currentGame.status = 2
          }
        }, 1000)
      }
    },
    reset() {
      this.runningOutWarning = false
      this.currentGame = this.createDefaultCurrentGame()
      this.resetTimer()
    },
    resetTimer() {
      clearInterval(this.timer)
      this.timer = null
      this.time = this.currentGame.status === 0 ? this.gameDuration : 0
    },
    onConfirmClick() {
      if (this.modalDataId === 1) {
        this.onWeekSubmit()
      } else if (this.modalDataId === 2) {
        this.onDaySubmit()
      } else if (this.modalDataId === 3) {
        this.onGameSubmit()
      } else if (this.modalDataId === 4) {
        // force complete game
        this.time = 0
        this.onGameSubmit()
      } else {
      }
    },
    async detectGameChange(game) {
      this.localTimestamp = moment().unix()
      let timestampGap = this.localTimestamp - game.time
      let gameDuration = this.gameDuration
      let gameStartGracePeriod = this.gameStartGracePeriod
      if (game.status === 1) {
        if (timestampGap <= gameDuration && timestampGap >= gameStartGracePeriod) {
          await this.startTimer(gameDuration - timestampGap)
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'allTeamWeekDays',
      'allTeamWeeks',
      'allTeamWeekWorkAllocations',
      'currentUser',
      'game',
      'teamWeekWorkAllocationsStatus',
      'triggeredGame'
    ]),
    activeTeamWeek() {
      return this.activeTeamWeeks ? this.activeTeamWeeks.find((tw) => tw.status_id === 2) : null
      // return this.activeTeamWeeks.find((tw) => tw.status_id === 2)
    },
    activeTeamWeekDay() {
      return this.activeTeamWeek
        ? this.activeTeamWeek.team_week_days.find((wd) => wd.current_day)
        : null
      // return this.activeTeamWeek.team_week_days.find((wd) => wd.current_day)
    },
    activeTeamWeeks() {
      return this.allTeamWeekDays ? this.allTeamWeekDays.team_weeks : null
    },
    hasWorkAllocation() {
      return this.teamWeekWorkAllocationsStatus
    },
    nextTeamWeek() {
      let nextWeek = null
      let weeks = this.activeTeamWeeks
      let nextTeamWeekIndex = weeks.indexOf(this.activeTeamWeek) + 1
      if (nextTeamWeekIndex < weeks.length) {
        nextWeek = weeks[nextTeamWeekIndex]
      }

      return nextWeek
    },
    isFriday() {
      return this.activeTeamWeekDay.day_name === 'Friday'
    },
    nextTeamWeekDay() {
      let nextDay = null
      let weekDays = this.activeTeamWeek.team_week_days
      let nextTeamWeekDayIndex = weekDays.indexOf(this.activeTeamWeekDay) + 1
      if (nextTeamWeekDayIndex < weekDays.length) {
        nextDay = weekDays[nextTeamWeekDayIndex]
      }

      return nextDay
    },
    prettyTime() {
      if (this.time <= 10 && this.time > 0) {
        this.runningOutWarning = true
      }
      let time = this.time / 60
      let minutes = parseInt(time)
      let seconds = Math.round((time - minutes) * 60)
      return minutes + ':' + seconds
    },
    gameTrigger() {
      let status = this.currentGame.status
      let triggerName = ''
      let disabled = false
      if (status === 0) {
        triggerName = 'Start Game'
      } else if (status === 1) {
        triggerName = `${this.activeTeamWeekDay.day_name} is in progress`
        disabled = true
      } else if (status === 2) {
        triggerName = `${this.activeTeamWeekDay.day_name} has completed`
        disabled = true
      } else {
        status = -1
        triggerName = 'Unknown'
        disabled = true
      }

      return {
        status: status,
        triggerName: triggerName,
        disabled: disabled
      }
    }
  },
  watch: {
    currentGame: {
      handler(game) {
        this.detectGameChange(game)
        if (game.status === 1) {
          this.$emit('gameProcessing', true)
        } else {
          this.$emit('gameProcessing', false)
        }
      },
      deep: true
    },
    async pageVisibilityTimeGap(newVal) {
      // wait for 3s to reload game status if page is idle
      if (newVal > 3000) {
        await this.getGame()
      }
    },
    async loading(value) {
      if (!value) {
        await this.reset()
        await this.getGame()
        await this.getTeamWeekWorkAllocationsStatus()
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
#clock {
  font-family: 'Share Tech Mono', monospace;
  color: #545454;
  z-index: 999;
  .time {
    font-size: 4.5rem !important;
  }

  .pending {
    color: #eeeeee;
  }
}

.b-avatar.disabled {
  opacity: 0.2;
}

.modal-img {
  height: 8.5rem;
}

.action-button {
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}

.control-loader {
  height: 480px;
}
</style>
