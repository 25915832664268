var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work-allocation-input"},[_c('b-avatar',{staticClass:"align-self-center",attrs:{"src":_vm.avatar,"size":"4.5em","text":_vm.getNameInitials(_vm.userName),"variant":"light","badge":_vm.workAllocations > 0,"badge-top":"","badge-variant":_vm.hasNew ? 'warning' : 'new',"badge-offset":"-5px","button":""},scopedSlots:_vm._u([(_vm.workAllocations > 0)?{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.workAllocations))])]},proxy:true}:null],null,true)}),_vm._v(" "),_c('p',{staticClass:"mt-2 mb-1"},[_vm._v(_vm._s(_vm.userName))]),_vm._v(" "),_c('div',{staticClass:"d-flex w-50 m-auto work-volume text-center"},[_c('div',_vm._g({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'Decrease',
        placement: 'bottom',
        classes: ['light'],
        offset: 5
      }),expression:"{\n        content: 'Decrease',\n        placement: 'bottom',\n        classes: ['light'],\n        offset: 5\n      }"}],staticClass:"align-self-center p-1 control-area",class:{ disabled: !_vm.hasNew }},_vm.hasNew ? { click: _vm.decrement } : {}),[_c('div',{staticClass:"arrow-left align-self-center"})]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newWorkAllocations),expression:"newWorkAllocations"}],staticClass:"work-volume-count mx-auto align-self-center",attrs:{"type":"number","name":"work-volume","min":"0","oninput":"validity.valid||(value='');"},domProps:{"value":(_vm.newWorkAllocations)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newWorkAllocations=$event.target.value}}}),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'Increase',
        placement: 'bottom',
        classes: ['light'],
        offset: 5
      }),expression:"{\n        content: 'Increase',\n        placement: 'bottom',\n        classes: ['light'],\n        offset: 5\n      }"}],staticClass:"align-self-center p-1 control-area",on:{"click":_vm.increment}},[_c('div',{staticClass:"arrow-right align-self-center ml-auto"})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }