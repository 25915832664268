<template>
  <div class="game-settings-control">
    <b-form @submit.prevent="onGameSettingsSubmit">
      <div class="d-flex">
        <div class="align-self-center mr-4">
          <label class="settings-label pl-0 mb-2 col-12">Require approval code?</label>
          <div class="d-flex">
            <b-form-radio
              v-model="approvalCodeValidation"
              name="ac-radios"
              :value="1"
              :disabled="loadingGameSettings"
              class="align-self-center d-inline-block"
            >
              <span>Yes</span>
            </b-form-radio>
            <b-form-radio
              v-model="approvalCodeValidation"
              name="ac-radios"
              :value="0"
              :disabled="loadingGameSettings"
              class="align-self-center d-inline-block mx-4"
            >
              <span>No</span>
            </b-form-radio>
          </div>
        </div>
        <div class="align-self-center">
          <label class="settings-label pl-0 mb-2 col-12">Work Complexity</label>
          <div class="d-flex">
            <b-form-radio
              v-model="workComplexity"
              name="wc-radios"
              :value="2"
              :disabled="loadingGameSettings"
              class="align-self-center d-inline-block"
            >
              <span>Letter</span>
            </b-form-radio>
            <b-form-radio
              v-model="workComplexity"
              name="wc-radios"
              :value="1"
              :disabled="loadingGameSettings"
              class="align-self-center d-inline-block mx-4"
            >
              <span>Table</span>
            </b-form-radio>
          </div>
        </div>
      </div>
      <div class="d-flex action-button">
        <div class="align-self-center ml-auto">
          <processing-button
            key="btn-work"
            variant="primary"
            size="sm"
            label="Save"
            class="align-self-center btn-shadow ml-auto"
            :processing="processing"
            :disabled="!gameSettingsChanged"
          ></processing-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
export default {
  props: {
    teamId: {
      type: [Number, String],
      default: null
    }
  },
  components: {
    ProcessingButton
  },
  data() {
    return {
      processing: false,
      workComplexity: null,
      approvalCodeValidation: null,
      gameSettingsChanged: false,
      loadingGameSettings: true
    }
  },
  methods: {
    ...mapActions(['fetchTeamGameSettings', 'updateTeamGameSettings']),
    async getTeamGameSettings() {
      this.loadingGameSettings = true
      await this.fetchTeamGameSettings(this.teamId)
      this.workComplexity = this.teamWorkComplexity
      this.approvalCodeValidation = this.teamApprovalCodeValidation
      this.loadingGameSettings = false
    },
    async onGameSettingsSubmit() {
      let payload = {
        id: this.teamId,
        work_complexity: this.workComplexity,
        approval_code: this.approvalCodeValidation
      }

      this.processing = true
      this.loadingGameSettings = true
      await this.updateTeamGameSettings(payload)
      this.processing = false
      this.loadingGameSettings = false
      this.gameSettingsChanged = false
    },
    resetData() {
      this.workComplexity = null
      this.approvalCodeValidation = null
      this.gameSettingsChanged = false
    }
  },
  computed: {
    ...mapGetters(['teamWorkComplexity', 'teamApprovalCodeValidation'])
  },
  created() {
    if (this.teamId) {
      this.getTeamGameSettings()
    }
  },
  watch: {
    teamId(newVal) {
      if (newVal) {
        this.resetData()
        this.getTeamGameSettings()
      }
    },
    workComplexity(newVal, oldVal) {
      if (oldVal !== null && !this.loadingGameSettings) {
        this.gameSettingsChanged = true
      }
    },
    approvalCodeValidation(newVal, oldVal) {
      if (oldVal !== null && !this.loadingGameSettings) {
        this.gameSettingsChanged = true
      }
    }
  }
}
</script>
