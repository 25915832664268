import { role } from '@/constants/config'
import i18n from '@/i18n'

export const menuItems = [
  {
    id: 99,
    icon: 'fal fa-chart-simple',
    label: i18n.t('menu.vmb-menu.dashboard'),
    to: 'dashboard'
  },
  {
    id: 3,
    icon: 'fal fa-handshake',
    label: i18n.t('menu.vmb-menu.what-went-well'),
    to: 'what-went-well'
  },
  {
    id: 4,
    icon: 'fal fa-microphone',
    label: i18n.t('menu.vmb-menu.communications'),
    to: 'communication'
  },
  {
    id: 5,
    icon: 'fal fa-envelope-open-text',
    label: i18n.t('menu.vmb-menu.feedback'),
    to: 'feedback'
  },
  {
    id: 6,
    icon: 'fal fa-gauge-high',
    label: i18n.t('menu.vmb-menu.performance'),
    to: 'performance'
  },
  {
    id: 1,
    icon: 'fal fa-headset',
    label: i18n.t('menu.vmb-menu.problems'),
    to: 'problems'
  },
  {
    id: 2,
    icon: 'fal fa-lightbulb-on',
    label: i18n.t('menu.vmb-menu.actions'),
    to: 'actions'
  },
  {
    id: 7,
    icon: 'fal fa-people-simple',
    label: i18n.t('menu.vmb-menu.people'),
    to: 'people'
  }
]
// export default data;

export const adminMenuItems = [
  {
    id: 'game-control',
    icon: 'gamepad-modern',
    label: 'Game Control',
    to: 'game-control',
    role: [role.ADMIN, role.GAMECREATOR]
  },
  {
    id: 'scores',
    icon: 'hundred-points',
    label: 'Score Centre',
    to: 'scores',
    role: [role.ADMIN, role.GAMECREATOR]
  },
  {
    id: 'audit-log',
    icon: 'book',
    label: 'Audit Log',
    to: 'audit-log',
    role: [role.ADMIN, role.GAMECREATOR]
  },
  {
    id: 'teams',
    icon: 'people-group',
    label: 'Teams',
    to: 'teams',
    role: [role.ADMIN, role.GAMECREATOR]
  },
  {
    id: 'users',
    icon: 'users',
    label: 'Users',
    to: 'users',
    role: [role.ADMIN, role.GAMECREATOR]
  },
  {
    id: 'approval-codes',
    icon: 'thumbs-up',
    label: 'Approval Codes',
    to: 'approval-codes',
    role: [role.ADMIN, role.GAMECREATOR]
  },
  {
    id: 'claim-master',
    icon: 'handshake',
    label: 'Claim Master',
    to: 'claim-master',
    role: [role.ADMIN, role.GAMECREATOR]
  }
]

export const topMenuItems = [
  {
    id: 1,
    label: i18n.t('menu.home'),
    to: 'home'
  },
  {
    id: 2,
    label: i18n.t('menu.rules'),
    to: 'rules'
  },
  {
    id: 3,
    label: i18n.t('menu.policies'),
    to: 'policies'
  },
  {
    id: 4,
    label: i18n.t('menu.systems'),
    to: 'lodgements'
  },
  {
    id: 5,
    label: i18n.t('menu.vmb'),
    to: 'vmb'
  }
]

export function getTopMenuItems() {
  return [
    {
      id: 'home',
      label: i18n.t('menu.home'),
      to: 'home'
    },
    {
      id: 'rules',
      label: i18n.t('menu.rules'),
      to: 'rules'
    },
    {
      id: 'policies',
      label: i18n.t('menu.policies'),
      to: 'policies'
    },
    {
      id: 'lodgements',
      label: i18n.t('menu.systems'),
      to: 'lodgements'
    },
    {
      id: 'dashboard',
      label: i18n.t('menu.vmb'),
      to: 'dashboard'
    },
    {
      id: 'management',
      label: i18n.t('menu.profile.management'),
      to: 'management'
    }
  ]
}

export const managementTabs = [
  {
    id: 1,
    label: 'Teams',
    to: 'teams',
    order: 1,
    role: [role.ADMIN, role.GAMECREATOR]
  },
  {
    id: 2,
    label: 'Users',
    to: 'users',
    order: 2,
    role: [role.ADMIN, role.GAMECREATOR]
  },
  {
    id: 3,
    label: 'Approval Codes',
    to: 'approval-codes',
    order: 3,
    role: [role.ADMIN]
  },
  {
    id: 4,
    label: 'Claim Master',
    to: 'claim-master',
    order: 4,
    role: [role.ADMIN]
  }
]
