<template>
  <div class="game-financials-control">
    <b-card class="game-financials-card" body-class="game-financials-card-content">
      <div class="header d-flex mb-4">
        <h4 class="m-0 animate__animated animate__fadeIn">Game Financials</h4>
      </div>
      <div class="w-100 d-flex flex-column flex-lg-row py-2 game-financials-header">
        <p class="mb-0 w-20 w-sm-100 align-middle text-center">Description</p>
        <p class="mb-0 w-20 w-sm-100 align-middle text-center" @click="orderGameFinancials">
          <span>Amount</span>
          <i class="fa ml-1" :class="orderedGameFinancials ? 'fa-caret-up' : 'fa-caret-down'"></i>
        </p>
        <p class="mb-0 w-20 w-sm-100 align-middle text-center">Type</p>
        <p class="mb-0 w-15 w-sm-100 align-middle text-center">Week</p>
        <p class="mb-0 w-15 w-sm-100 align-middle text-center">Day</p>
        <p class="mb-0 w-10 w-sm-100 align-middle text-center">Actions</p>
      </div>
      <game-financials-control-form
        :key="`new-financial-form-${reloadedForm}`"
        v-model="newFinancial"
        :loading="loading"
        :processing="processing"
        @itemAdded="onNewFinancialAdd"
        class="my-3"
      ></game-financials-control-form>
      <b-overlay :show="loadingGameFinancials" rounded="sm" bg-color="white">
        <vue-perfect-scrollbar
          class="scroll"
          :settings="{ suppressScrollX: true, wheelPropagation: false }"
        >
          <div
            v-for="(gameFinancial, index) in sortGameFinancials"
            :key="`fin-${index}`"
            class="mb-2"
          >
            <game-financials-control-form
              v-if="editedItemIds.includes(gameFinancial.id)"
              key="new-financial-form"
              edit
              v-model="sortGameFinancials[index]"
              @itemEdited="onItemEditConfirm(sortGameFinancials[index])"
              @itemCancelled="onItemEditCancel(sortGameFinancials[index])"
              class="my-3"
            ></game-financials-control-form>
            <game-financials-item
              v-else
              :key="`fin-item-${index}-${sortGameFinancials[index].status}`"
              v-model="sortGameFinancials[index]"
              @edited="onItemEdit(sortGameFinancials[index])"
              @deleted="onItemDelete(sortGameFinancials[index])"
              class="d-flex py-3 financial-item animate__animated animate__fadeIn"
            ></game-financials-item>
          </div>
        </vue-perfect-scrollbar>
        <template #overlay>
          <running-loader loading></running-loader>
        </template>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { helperMixin } from '@/mixins/helperMixin'
import moment from 'moment'
import GameFinancialsControlForm from './GameFinancialsControlForm.vue'
import GameFinancialsItem from './GameFinancialsItem.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
export default {
  components: {
    GameFinancialsControlForm,
    GameFinancialsItem,
    ProcessingButton
  },
  props: {
    teamId: {
      type: [Number, String],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allGameFinancials: [],
      editedItemIds: [],
      financialTypes: ['Debit', 'Credit'],
      gameFinancialChanged: false,
      loadingGameFinancials: false,
      newFinancial: this.createNewFinancial(),
      orderedGameFinancials: false,
      processing: false,
      reloadedForm: 0,
      teamWeekDays: [],
      teamWeeks: []
    }
  },
  mixins: [helperMixin],
  methods: {
    ...mapActions([
      'addTeamGameFinancials',
      'deleteTeamGameFinancials',
      'fetchNewTeamGameFinancials',
      'fetchTeamGameFinancials',
      'updateTeamGameFinancials'
    ]),
    createNewFinancial() {
      return {
        id: this.createUUID(),
        description: '',
        amount: null,
        type: 'Credit',
        team_week_id: null,
        team_week_name: 'loading...',
        team_week_day_id: null,
        team_week_day_name: 'loading...',
        editing: false,
        status: 'add',
        created_at: moment().format()
      }
    },
    async getGameFinancials() {
      this.loadingGameFinancials = true
      await this.fetchTeamGameFinancials(this.teamId)
      this.loadingGameFinancials = false
      this.allGameFinancials = await _.cloneDeep(this.gameFinancials)
      await this.allGameFinancials.map((fin) => {
        fin.editing = true
        fin.status = null
      })
    },
    orderGameFinancials() {
      this.orderedGameFinancials = !this.orderedGameFinancials
      this.sortGameFinancials.sort((a, b) =>
        this.orderedGameFinancials ? b.amount - a.amount : a.amount - b.amount
      )
    },
    async onNewFinancialAdd() {
      let item = await this.newFinancial
      let payload = [
        {
          team_id: this.teamId,
          team_week_id: item.team_week_id,
          team_week_day_id: item.team_week_day_id,
          financial_id: null,
          financial_description: item.description,
          financial_amount: Number(item.amount),
          financial_type: item.type.toUpperCase(),
          editing: item.editing,
          status: item.status
        }
      ]
      this.newFinancial = await this.createNewFinancial()
      this.reloadedForm++
      this.processing = true
      await this.addTeamGameFinancials(payload)
      await this.fetchNewTeamGameFinancials(this.teamId)
      this.allGameFinancials = await _.cloneDeep(this.gameFinancials)
      this.processing = false
    },
    async onItemEditConfirm(item) {
      let post = [
        {
          team_id: this.teamId,
          team_week_id: item.team_week_id,
          team_week_day_id: item.team_week_day_id,
          financial_id: item.status === 'add' ? null : item.id,
          financial_description: item.description,
          financial_amount: Number(item.amount),
          financial_type: item.type.toUpperCase(),
          editing: true,
          status: 'update'
        }
      ]

      let state = [
        {
          id: item.id,
          team_week_id: item.team_week_id,
          type: item.type.toUpperCase(),
          amount: Number(item.amount),
          created_at: item.created_at,
          updated_at: item.created_at,
          team_week_day_id: item.team_week_day_id,
          description: item.description,
          team_week_name: item.team_week_name,
          team_week_day_name: item.team_week_day_name,
          status: item.status
        }
      ]

      await this.removeItem(this.editedItemIds, item.id)
      await this.updateTeamGameFinancials({ post: post, state: state })
      await this.resetData()
    },
    onItemEditCancel(item) {
      let gameFinancials = this.allGameFinancials
      let index = gameFinancials.indexOf(item)
      // check if item property is changed and restore item with existing one
      if (item.id) {
        let existingItem = _.cloneDeep(this.gameFinancials.find((i) => i.id === item.id))
        existingItem.editing = true
        existingItem.status = null
        if (!_.isEqual(item, existingItem)) {
          this.$set(gameFinancials, index, existingItem)
        }
        this.removeItem(this.editedItemIds, item.id)
      }
    },
    onItemEdit(item) {
      this.editedItemIds.push(item.id)
    },
    async onItemDelete(item) {
      let gameFinancials = await this.allGameFinancials
      let index = await gameFinancials.indexOf(item)
      let payload = [
        {
          team_id: this.teamId,
          team_week_id: item.team_week_id,
          team_week_day_id: item.team_week_day_id,
          financial_id: item.id,
          financial_description: item.description,
          financial_amount: Number(item.amount),
          financial_type: item.type.toUpperCase(),
          editing: item.editing,
          status: 'delete'
        }
      ]
      await this.$delete(gameFinancials, index)
      await this.deleteTeamGameFinancials(payload)
    },
    removeItem(items, item) {
      const itemIndex = items.indexOf(item)
      if (itemIndex > -1) items.splice(itemIndex, 1)
    },
    async resetData() {
      this.gameFinancialChanged = false
      this.reloadedForm = 0
      this.editedItemIds = []
    }
  },
  computed: {
    ...mapGetters(['gameFinancials', 'allTeamWeekDays']),
    activeTeamWeeks() {
      return this.allTeamWeekDays.team_weeks
    },
    activeTeamWeek() {
      let teamWeek = this.activeTeamWeeks.find((tw) => tw.status_id === 2)
      return teamWeek
    },
    activeTeamWeekDay() {
      return this.activeTeamWeek.team_week_days.find((wd) => wd.current_day)
    },
    sortGameFinancials() {
      return _.orderBy(this.allGameFinancials, (item) => moment(item.created_at).utc(), ['desc'])
    }
  },
  created() {
    if (this.teamId) {
      this.getGameFinancials()
    }
  },
  watch: {
    teamId(newVal) {
      if (newVal) {
        this.resetData()
        this.getGameFinancials()
      }
    },
    sortGameFinancials: {
      handler(value) {
        if (value) {
          let updatedItems = value.filter((item) => item.status !== null)
          if (updatedItems.length > 0) {
            this.gameFinancialChanged = true
          } else {
            this.gameFinancialChanged = false
          }
        }
      },
      deep: true
    },
    allTeamWeekDays: {
      handler(value) {
        if (value) {
          this.getGameFinancials()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.game-financials {
  &-control {
    .scroll {
      padding-right: unset;
      margin-right: unset;
      height: 400px;

      .ps__thumb-y {
        right: 0;
      }

      .financial-item {
        border-bottom: 1px solid #f3f3f3;
      }
    }
  }

  &-header {
    p {
      font-family: 'Nunito Bold';
    }
  }

  &-card {
    height: 650px;
  }

  &-input {
    font-size: 0.8rem;
    padding: 0.3rem 0.75rem;
  }

  &-actions {
    font-size: 1.2rem;
  }
}

.ps {
  overflow-y: auto !important;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  display: none; /* Safari and Chrome */
}
</style>
