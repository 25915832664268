<template>
  <div class="game-control">
    <dashboard-layout hideWeekDay>
      <template #header>
        <h4 class="align-self-center pb-0 mb-0">Game Control</h4>
      </template>
      <template #body>
        <b-row v-if="hasSimulationTeams">
          <b-col cols="12" xl="5" lg="12" md="12" class="mb-4">
            <b-row>
              <b-col cols="12 mb-4">
                <b-card class="game-control-card" body-class="game-control-card-content">
                  <div class="header d-flex mb-4">
                    <h4
                      :key="`wd-setting-title-${selectedWdToggleId}`"
                      class="mb-0 align-self-center animate__animated animate__fadeIn"
                    >
                      {{ weekDaySettingTitle }}
                    </h4>
                    <b-dropdown
                      v-show="selectedWdToggleId === 2"
                      class="dropdown-menu-right"
                      variant="empty"
                      toggle-class="p-0 d-flex"
                      menu-class="rollback-modal btn-shadow animate__animated animate__fadeIn"
                      no-caret
                    >
                      <template slot="button-content">
                        <i class="fa fa-info-circle align-self-center text-info ml-2"></i>
                      </template>
                      <b-dropdown-form>
                        <div>
                          <h6>Details:</h6>
                          <p>
                            This feature allows you to rollback your team's progress to a particular
                            day within the simulation.
                          </p>
                          <h6>Things to note:</h6>
                          <ol>
                            <li>Use this feature with care.</li>
                            <li>
                              The rollback feature can only be used to rollback to a day in the
                              past. All data will be erased up until and including the selected day
                              you rollback to.
                            </li>
                            <li>
                              There is no way to retrieve the forfeited data, once you have
                              confirmed the rollback.
                            </li>
                            <li>Please consider this before using this feature.</li>
                          </ol>
                        </div>
                      </b-dropdown-form>
                    </b-dropdown>
                    <i
                      v-if="gameProcessing && selectedWdToggleId === 2"
                      class="fa fa-ban align-self-center ml-2 text-danger animate__animated animate__fadeIn"
                      v-tooltip="{
                        content: `Rollback is currenly not available, waiting for game day to close`,
                        placement: 'bottom',
                        classes: ['light'],
                        offset: 5
                      }"
                    ></i>
                    <base-toggle-button
                      :options="weekDaySettingsToggle"
                      :buttonSize="230"
                      v-model="selectedWdToggleId"
                      class="ml-auto align-self-center"
                    ></base-toggle-button>
                  </div>
                  <team-week-day-control
                    v-show="selectedWdToggleId === 1"
                    :key="`team-week-day-control-${componentUpdated}`"
                    :loading="loadingAllTeamWeekDays"
                    @gameProcessing="processingGame"
                    class="animate__animated animate__fadeIn"
                  ></team-week-day-control>
                  <team-week-day-rollback-control
                    v-show="selectedWdToggleId === 2"
                    :loading="loadingAllTeamWeekDays"
                    :disabled="gameProcessing"
                    class="animate__animated animate__fadeIn"
                    @rollbackSaved="onRollbackSave"
                  ></team-week-day-rollback-control>
                </b-card>
              </b-col>

              <b-col cols="12 mb-4">
                <b-card class="game-settings-card" body-class="game-control-card-content">
                  <div class="header d-flex mb-4">
                    <h4 class="m-0 align-self-center animate__animated animate__fadeIn">
                      Game Settings
                    </h4>
                  </div>
                  <game-settings-control :teamId.sync="activeTeam.teamId"></game-settings-control>
                </b-card>
              </b-col>

              <b-col cols="12">
                <game-financials-control
                  :teamId.sync="activeTeam.teamId"
                  :loading="loadingAllTeamWeekDays"
                ></game-financials-control>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" xl="7" lg="12" md="12">
            <b-row>
              <b-col cols="12">
                <work-allocations-table :loading="loadingAllTeamWeekDays"></work-allocations-table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-card
          v-if="!loadingSimulationTeams && !hasSimulationTeams"
          class="d-flex flex-column justify-content-center align-items-center h-100"
        >
          <no-item-prompt text="Please add teams in this simulation"></no-item-prompt>
        </b-card>
      </template>
    </dashboard-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formMixin } from '@/mixins/formMixin'
import { gameSettingsToggle } from '@/data/admin'
import { helperMixin } from '@/mixins/helperMixin'
import { initializeTeam } from '@/utils/initialization'
import { managementMixin } from '@/mixins/managementMixin'
import { quickAllocations } from '@/constants/config'
import { weekDaySettingsToggle } from '@/components/Control/config'
import BaseNoItemPrompt from '@/components/Common/BaseNoItemPrompt.vue'
import BaseToggleButton from '@/components/Common/BaseToggleButton.vue'
import DashboardLayout from '@/components/Layouts/DashboardLayout'
import GameFinancialsControl from '@/components/Control/GameFinancialsControl.vue'
import GameFinancialsControlSlideOne from '@/components/Control/GameFinancialsControlSlideOne.vue'
import GameFinancialsControlSlideTwo from '@/components/Control/GameFinancialsControlSlideTwo.vue'
import GameSettingsControl from '@/components/Control/GameSettingsControl.vue'
import IconToggle from '@/components/Common/IconToggle.vue'
import OverlayLoader from '@/components/Common/OverlayLoader.vue'
import TeamSelector from '@/components/Control/TeamSelector.vue'
import TeamWeekDayControl from '@/components/Control/TeamWeekDayControl.vue'
import TeamWeekDayRollbackControl from '@/components/Control/TeamWeekDayRollbackControl.vue'
import WorkAllocationsControl from '@/components/Control/WorkAllocationsControl.vue'
import WorkAllocationsTable from '@/components/Control/WorkAllocationsTable.vue'
export default {
  components: {
    BaseToggleButton,
    DashboardLayout,
    GameFinancialsControl,
    GameFinancialsControlSlideOne,
    GameFinancialsControlSlideTwo,
    GameSettingsControl,
    IconToggle,
    'no-item-prompt': BaseNoItemPrompt,
    OverlayLoader,
    TeamSelector,
    TeamWeekDayControl,
    TeamWeekDayRollbackControl,
    WorkAllocationsControl,
    WorkAllocationsTable
  },
  mixins: [formMixin, helperMixin, managementMixin],
  data() {
    return {
      configuration: this.createNewConfiguration(),
      activeTeamIndex: 0,
      activeTeam: initializeTeam(),
      loading: true,
      loadingAllTeamWorkAllocations: true,
      teams: [],
      currentTeamWeekDays: null,
      completedTeamWeekDays: null,
      teamWorkAllocations: null,
      message: '',
      runningOutWarning: false,
      showModal: false,
      gameProcessing: false,
      updatedTeamWorkAllocations: null,
      processingSettings: false,
      updatedSettings: {
        slideOne: null,
        slideTwo: []
      },
      componentUpdated: 0,
      quickAllocations,
      quickAssigned: 0,
      workAllocationsControlStatus: true,
      gameSettingSlide: 1,
      gameFinancialsControlStatus: true,
      selectedToggle: 1,
      gameSettingsToggle,
      weekDaySettingsToggle,
      selectedWdToggleId: 1,
      loadingAppConfiguration: false,
      loadingGameSettings: true,
      selectedTestToggle: 1,
      loadingAllTeamWeekDays: false
    }
  },
  methods: {
    ...mapActions([
      'fetchAllTeamWeekDays',
      'fetchAllTeamWeeks',
      'fetchTeamWeekDays',
      'submitConfiguration',
      'submitTeamWeekSettings'
    ]),
    createNewConfiguration() {
      return {
        locale: null,
        currency: null
      }
    },
    async getAllTeamWeekDays() {
      await this.fetchAllTeamWeekDays(this.activeTeam.teamId)
    },
    async getTeamData() {
      this.loadingAllTeamWeekDays = true
      await Promise.all([this.getAllTeamWeekDays(), this.getTeamWeeks()])
      this.loadingAllTeamWeekDays = false
    },
    async getTeamWeeks() {
      await this.fetchAllTeamWeeks(this.activeTeam.teamId)
    },
    async onRollbackSave() {
      this.selectedWdToggleId = 1 // switch to game progression
      await this.getAllTeamWeekDays()
    },
    async processingGame(value) {
      this.gameProcessing = value
    },
    async reloadWorkAllocations() {
      this.teamWorkAllocations = null
      await this.getTeamWorkAllocations()
      this.quickAssigned = 0
    }
  },
  computed: {
    ...mapGetters([
      'allTeams',
      'allTeamWeekDays',
      'allTeamWeeks',
      'currency',
      'currentSimulation',
      'currentSimulationTeam',
      'locale',
      'simulationTeams',
      'teamWeekDays'
    ]),
    ...mapGetters('loading', ['loadingSimulationTeams', 'loadingTeamWeekDays', 'loadingTeamWeeks']),
    hasSimulationTeams() {
      return this.simulationTeams.length > 0
    },
    weekDaySettingTitle() {
      return this.weekDaySettingsToggle.find((s) => s.id === this.selectedWdToggleId).title
    }
  },
  watch: {
    componentUpdated() {
      this.reloadWorkAllocations()
    },
    currentSimulationTeam: {
      async handler(newValue) {
        if (newValue) {
          this.activeTeam = _.cloneDeep(newValue)
          await this.getTeamData()
        } else {
          this.activeTeam = initializeTeam()
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
