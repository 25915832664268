var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rollback-card"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-row',{staticClass:"text-center mx-auto w-100 my-3"},[_c('b-col',{staticClass:"p-0 text-center",attrs:{"cols":"2"}},[_c('b-avatar',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.teamWeek.week_name,
            placement: 'bottom',
            classes: ['light'],
            offset: 10
          }),expression:"{\n            content: teamWeek.week_name,\n            placement: 'bottom',\n            classes: ['light'],\n            offset: 10\n          }"}],staticClass:"btn-shadow",attrs:{"size":"3.5em","text":_vm.getNameInitials(_vm.teamWeek.week_name),"variant":_vm.setWeekVariant(_vm.teamWeek.status_id),"disabled":_vm.disableWeek(_vm.teamWeek) || _vm.disabled}})],1),_vm._v(" "),_vm._l((_vm.teamWeek.team_week_days),function(day,index){return _c('b-col',{key:("day-" + index),staticClass:"p-0",attrs:{"cols":"2"}},[_c('div',{staticClass:"rollback-wrapper"},[_c('b-avatar',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: day.day_name,
              placement: 'bottom',
              classes: ['light'],
              offset: 10
            }),expression:"{\n              content: day.day_name,\n              placement: 'bottom',\n              classes: ['light'],\n              offset: 10\n            }"}],staticClass:"btn-shadow",attrs:{"size":"3.5em","text":_vm.getNameInitials(day.day_name),"variant":_vm.setWeekDayVariant(day),"disabled":_vm.disableDay(day) || _vm.disabled,"button":""},on:{"click":function($event){return _vm.onRollbackDayClick(day)}}}),_vm._v(" "),(day.id === _vm.value)?_c('span',{key:("check-" + _vm.value),staticClass:"numberCircle animate__animated animate__bounceIn"},[_c('i',{staticClass:"fa fa-history"})]):_vm._e()],1)])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }